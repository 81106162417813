import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchReservaTodasSuccess,
  fetchReservaTodasFailure,
  createReservaSuccess,
  createReservaFailure,
  approveReservaSuccess,
  cancelReservaSuccess,
  cancelReservaFailure,
  deleteReservaFailure
} from './actions';
import {
  APPROVE_RESERVA_REQUEST,
  CANCEL_RESERVA_REQUEST,
  CREATE_RESERVA_REQUEST,
  DELETE_RESERVA_REQUEST,
  FETCH_RESERVA_TODAS_REQUEST
} from './types';
import { approveReservaApi, cancelReservaApi, createReservaApi, reservaTodasApi, deleteReservaApi } from '../../api/endpoints/reservaApi';

export function* fetchReservaTodasSaga(): Generator<any, void, any[]> {
  try {
    const reservas = yield call(reservaTodasApi);
    if (reservas) {
      yield put(fetchReservaTodasSuccess(reservas));
    } else {
      yield put(fetchReservaTodasFailure('No data returned'));
    }
  } catch (error: any) {
    console.log('Erro ao buscar reservas: ' + error.message);
    yield put(fetchReservaTodasFailure(error.message));
  }
}

export function* createReservaSaga(action: any): Generator<any, void, any[]> {
  try {
    const reserva = action.payload;
    const data = yield call(createReservaApi, reserva);
    yield put(createReservaSuccess(data));
  } catch (error: any) {
    console.log('Erro ao criar reserva: ' + error.message);
    yield put(createReservaFailure(error.message));
  }
}

export function* approveReservaSaga(action: any): Generator<any, void, any[]> {
  try {
    const reserva = action.payload;
    const data = yield call(approveReservaApi, reserva);
    yield put(approveReservaSuccess(data.toString()));
  } catch (error: any) {
    console.log('Erro ao criar reserva: ' + error.message);
    yield put(createReservaFailure(error.message));
  }
}

export function* cancelReservaSaga(action: any): Generator<any, void, any> {
  try {
    yield call(cancelReservaApi, action.payload);
    yield put(cancelReservaSuccess(action.payload));
  } catch (error: any) {
    console.log('Erro ao cancelar reserva: ' + error.message);
    yield put(cancelReservaFailure(error.message));
  }
}

export function* deleteReservaSaga(action: any): Generator<any, void, any> {
  try {
    yield call(deleteReservaApi, action.payload);
  } catch (error: any) {
    console.log('Erro ao deletar reserva: ' + error.message);
    yield put(deleteReservaFailure(error.message));
  }
}

export function* watchReserva() {
  yield takeLatest(FETCH_RESERVA_TODAS_REQUEST, fetchReservaTodasSaga);
  yield takeLatest(CREATE_RESERVA_REQUEST, createReservaSaga);
  yield takeLatest(APPROVE_RESERVA_REQUEST, approveReservaSaga);
  yield takeLatest(CANCEL_RESERVA_REQUEST, cancelReservaSaga);
  yield takeLatest(DELETE_RESERVA_REQUEST, deleteReservaSaga);
}
