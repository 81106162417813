import { 
  FETCH_RESERVA_TODAS_REQUEST, 
  FETCH_RESERVA_TODAS_SUCCESS, 
  FETCH_RESERVA_TODAS_FAILURE, 
  CREATE_RESERVA_REQUEST, 
  CREATE_RESERVA_SUCCESS, 
  CREATE_RESERVA_FAILURE, 
  APPROVE_RESERVA_REQUEST, 
  APPROVE_RESERVA_SUCCESS, 
  APPROVE_RESERVA_FAILURE, 
  CANCEL_RESERVA_REQUEST, 
  CANCEL_RESERVA_SUCCESS,
  CANCEL_RESERVA_FAILURE,
  DELETE_RESERVA_REQUEST,
  DELETE_RESERVA_SUCCESS,
  DELETE_RESERVA_FAILURE } from './types';

export const fetchReservaTodasRequest = () => ({
  type: FETCH_RESERVA_TODAS_REQUEST,
});

export const fetchReservaTodasSuccess = (data: any[]) => ({
  type: FETCH_RESERVA_TODAS_SUCCESS,
  data,
});

export const fetchReservaTodasFailure = (error: any) => ({
  type: FETCH_RESERVA_TODAS_FAILURE,
  payload: error,
});

export const createReservaRequest = (reservaData: any) => ({
  type: CREATE_RESERVA_REQUEST,
  payload: reservaData,
});

export const createReservaSuccess = (data: any) => ({
  type: CREATE_RESERVA_SUCCESS,
  payload: data,
});

export const createReservaFailure = (error: any) => ({
  type: CREATE_RESERVA_FAILURE,
  payload: error,
});

export const approveReservaRequest = (reservaId: string) => ({
  type: APPROVE_RESERVA_REQUEST,
  payload: reservaId,
});

export const approveReservaSuccess = (reservaId: string) => ({
  type: APPROVE_RESERVA_SUCCESS,
  payload: reservaId,
});

export const approveReservaFailure = (error: string) => ({
  type: APPROVE_RESERVA_FAILURE,
  payload: error,
});

export const cancelReservaRequest = (reservaId: string) => ({
  type: CANCEL_RESERVA_REQUEST,
  payload: reservaId,
});

export const cancelReservaSuccess = (reservaId: string) => ({
  type: CANCEL_RESERVA_SUCCESS,
  payload: reservaId,
});

export const cancelReservaFailure = (error: string) => ({
  type: CANCEL_RESERVA_FAILURE,
  payload: error,
});

export const deleteReservaRequest = (reservaId: string) => ({
  type: DELETE_RESERVA_REQUEST,
  payload: reservaId,
});

export const deleteReservaSuccess = (reservaId: string) => ({
  type: DELETE_RESERVA_SUCCESS,
  payload: reservaId,
});

export const deleteReservaFailure = (error: string) => ({
  type: DELETE_RESERVA_FAILURE,
  payload: error,
});
