import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import './AdminPage.css';
import ReservationsTable from '../../components/ReservationsTable/ReservationsTable';
import { clearMessage, setMessage } from '../../store/states/message/actions';
import FuturisticMessage from '../../components/FuturisticMessage/FuturisticMessage';
import PieChartComponent from '../../components/PieChartComponent/PieChartComponent';
import { approveReservaRequest, cancelReservaRequest, fetchReservaTodasRequest } from '../../store/states/reserva/actions';
import { StatsPanel } from '../../components/StatsPanel/StatsPanel';

const AdminPage: React.FC = () => {
    const dispatch = useDispatch();
    const reservations = useSelector((state: RootState) => state.reserva.data);
    const [reservas, setReservas] = React.useState<any[]>([]);

    useEffect(() => {
        setReservas(reservations);
    }, [dispatch, reservations]);

    useEffect(() => {
        dispatch(fetchReservaTodasRequest());
    }, [dispatch]);

    const handleStatusChange = (id: string, action: 'approve' | 'cancel') => {
        if (action === 'approve') {
            dispatch(approveReservaRequest(id));
        } else if (action === 'cancel') {
            dispatch(cancelReservaRequest(id));
        }
        dispatch(setMessage(`Reserva ${action === 'approve' ? 'confirmada' : 'cancelada'} com sucesso!`));
        dispatch(fetchReservaTodasRequest());
    };

    return (
        <div className="futuristic-container animate-slide-in">
            <StatsPanel reservations={reservations} />
            <PieChartComponent reservations={reservas} />
            <ReservationsTable reservations={reservas} onStatusChange={handleStatusChange} />
            <FuturisticMessage onClose={() => dispatch(clearMessage())} />
        </div>
    );
};

export default AdminPage;
